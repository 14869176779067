import { defineChain } from 'viem';
// import { linea } from 'viem/chains';

export const ZytronMainnet = defineChain({
  id: 9901,
  name: 'Zytron Mainnet',
  network: 'Zytron Mainnet',
  nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  rpcUrls: { default: { http: ['https://rpc.zypher.network'] }, public: { http: ['https://rpc.zypher.network'] } },
  blockExplorers: { default: { name: 'blockscout', url: 'https://explorer.zypher.network' } },
  contracts: { multicall3: { address: '0xa8fAD960aCf062715e1fd3DBD0ee319B2d753b23', blockCreated: 167020 } },
  testnet: true,
});

export const mainnet = ZytronMainnet;

export const SupportChains = [mainnet] as const;

SupportChains.forEach((chain) => {
  (chain as any).iconUrl = `/chain/${chain.id}.svg`;
});

export const SupportChainIds = SupportChains.map((i) => i.id);

export type ISupportChainIds = (typeof SupportChainIds)[number];

export function IsSupportChainId(chainId: number): chainId is ISupportChainIds {
  return SupportChainIds.includes(chainId as any);
}

export const GameAppUrl = 'https://l3.cryptorumble.io' as const;
