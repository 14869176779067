import { useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { Modal, message, notification } from 'antd';
import { GameAppUrl, IsSupportChainId, SupportChains } from 'constants/chains';
import { GlobalVar } from 'constants/constants';
import React, { Fragment, useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { stateAppChain, stateAppChainInfo } from 'states/wallet';
import styled from 'styled-components';
import { ABI_CONST_MOUNTED } from 'types/ABI_CONST';
import { txEllipsis } from 'utils';
import { useAccount, useReadContract } from 'wagmi';
import Box from 'assets/images/airdrop/box-3.png';
import { zeroAddress } from 'viem';

const heroType = [
  'warrior',
  'mage',
  'assassin',
];
export const AppHeader: React.FC<{}> = (props) => {
  const _appChain = useSetRecoilState(stateAppChain);
  const chainInfo = useRecoilValue(stateAppChainInfo);
  // const wallet = useWallet();
  const acc = useAccount();
  const location = useLocation();
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  // const { openConnectModal } = useConnectModal();
  const [notificationApi, notificationCtx] = notification.useNotification();
  const [messageApi, messageCtx] = message.useMessage();
  const [modalApi, modalCtx] = Modal.useModal();
  GlobalVar.notification = notificationApi;
  GlobalVar.message = messageApi;
  GlobalVar.modal = modalApi;

  const boxesAmount = useReadContract({
    account: acc.address,
    abi: ABI_CONST_MOUNTED.Hero,
    address: chainInfo.Hero.address,
    functionName: 'balanceOf',
    args: [acc?.address ?? zeroAddress],
    query: {
      enabled: Boolean(acc?.address),
      refetchInterval: 3000,
    },
  });

  const hero = useMemo(() => {
    if (acc.address) {
      return heroType[Number(BigInt(acc.address) % BigInt(3))];
    }
    return '';
  }, [acc.address])

  useEffect(() => {
    const chainId = acc.chainId;
    if (!chainId) return;
    if (!IsSupportChainId(chainId)) return openChainModal?.();
    const target = SupportChains.find((c) => c.id === chainId);
    if (!target) return;
    _appChain(target);
  }, [acc]);

  return (
    <CptStyles hero={hero}>
      <div className="left">
        <NavLink className="logo" to="/">
          <img src="/logo.png" />
        </NavLink>
        {/* <NavLink to="/airdrop">Airdrop</NavLink> */}
        <NavLink to="/presale">Pre-Sale</NavLink>
        {/* <NavLink to="/whitelist">White List Test</NavLink> */}
        {/* <NavLink target="_blank" to="https://docs.cryptorumble.io/">
          Docs
        </NavLink> */}
        {/* <NavLink to="/game">Game</NavLink> */}
      </div>
      <div className="right">
        {
          location.pathname === '/' ? (
            <a className="launch" target="_blank" href={GameAppUrl}>
              Launch App
            </a>
          ) : (
            <div className="wallet-info">
              {acc.isConnected ? (
                <Fragment>
                  <div className="avatar" onClick={() => openAccountModal?.()}>
                    <div className="img" />
                    <div>{txEllipsis(acc.address, 4, 4)}</div>
                  </div>
                  <div className="box">
                    <div className="img" />
                    <div className="amount">
                      <span className="text">Claims</span>
                      <span className="num">x{boxesAmount?.data?.toString(10) ?? 0}</span>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="connect" onClick={() => openConnectModal?.()}>
                  Connect
                </div>
              )}
            </div>
          )
        }
      </div>
      {notificationCtx}
      {messageCtx}
      {modalCtx}
    </CptStyles>
  );
};

const CptStyles = styled.div<{ hero: string }>`
  box-sizing: border-box;
  width: 100vw;
  padding: 0 12px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 20;
  backdrop-filter: blur(5px);
  @media screen and (max-width: 1000px) {
    height: 60px;
  }
  > .right {
    > .launch {
      background-color: #FABE4B;
      border-radius: 25px;
      font-family: "Jockey One";
      font-size: 16px;
      line-height: 1.5;
      padding: 10px 36px;
      color: #121212;
      cursor: pointer;
    }
    > .wallet-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      font-family: "Jockey One";
      > .connect {
        background-color: #FABE4B;
        border-radius: 25px;
        font-size: 16px;
        line-height: 1.5;
        padding: 10px 36px;
        color: #121212;
        cursor: pointer;
      }
      > .box,
      > .avatar {
        font-size: 16px;
        border-radius: 24px;
        padding: 8px 22px 8px 8px;
        gap: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        background: #FFFFFF1A;
        border: 1px solid #FFFFFF1A;
        cursor: pointer;
        > .img {
          border-radius: 50%;
          width: 28px;
          height: 28px;
          background: url('https://zypher-static.s3.amazonaws.com/lib/CryptoRumble/public/static/player/hero/${props => props.hero}/1-140.webp') no-repeat center / cover;
        }
      }
      > .avatar  {
        @media screen and (max-width: 768px) {
          padding: 8px 12px;
          > .img {
            display: none;
          }
        }
      }
      > .box {
        cursor: default;
        @media screen and (max-width: 768px) {
          padding: 8px 12px;
          gap: 4px;
          > .amount > .text {
            display: none;
          }
        }
        > .img {
          border-radius: 50%;
          width: 28px;
          height: 28px;
          background: url(${Box}) no-repeat center / cover;
        }
        > .amount {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
          > .num {
            color: #F6B945;
          }
        }
      }
    }
  }
  > .left {
    display: flex;
    align-items: center;
    gap: 12px;
    > .logo {
      > img {
        height: 60px;
      }
    }
    @media screen and (max-width: 460px) {
      > a {
        /* display: none; */
        &.logo {
          display: none;
        }
      }
    }
    > a {
      transition: all ease 0.2s;
      color: #fff;
      &:hover {
        text-decoration: underline;
        transform: scale(1.1);
        color: #eee;
      }
    }
  }
`;
