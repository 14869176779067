import React from 'react'
import styled from 'styled-components';
import BgImage from 'assets/images/index/whitelist.webp';
import NavButton from 'components/app/NavButton';
import { useNavigate } from 'react-router-dom';

const WhiteListTest: React.FC = () => {
  const navigate = useNavigate();
  return (
    <WhiteListTestStyle style={{ backgroundImage: `url(${BgImage})` }}>
      <div className="container">
        <div className="intro">
          <div className="title">White List Test</div>
          <div className="desc">
            The Linea mainnet <span className="num">1000</span> whitelist testing event is underway.
            <br />
            Participants can earn limited-edition <span className="num">SBTs</span>, share a <span className="num">50,000</span> USDT reward pool
          </div>
          <NavButton
            className="btn"
            style={{
              padding: '8px 40px',
              minWidth: '200px',
              fontSize: '20px',
              fontWeight: 800,
              color: '#121212',
              textShadow: '0px 1px 0px #FFFFFF80',
            }}
          >
            Join!
          </NavButton>
        </div>
      </div>
    </WhiteListTestStyle>
  );
};

const WhiteListTestStyle = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  > .container {
    width: 100vw;
    min-height: 540px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      min-height: 360px;
    }
    background: radial-gradient(53.95% 84.26% at 50% 50%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
    > .intro {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px;
      color: #ffffff;
      text-transform: capitalize;
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 768px) {
        gap: 20px;
        font-size: 20px;
      }
      > .title {
        font-family: Kanit;
        font-weight: 700;
        font-size: 1em;
        line-height: 1.4;
      }
      > .desc {
        font-family: "Jockey One";
        font-size: 0.75em;
        line-height: 1.4;
        > .num {
          font-size: calc(1em * 4/3);
        }
      }
      > .btn {
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          margin-top: 10px;
        }
      }
    }
  }
`;


export default WhiteListTest;
