import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ViewBg1 from 'assets/images/presale/view-bg-1.webp';
import ViewBg2 from 'assets/images/airdrop/view-bg-2.jpg';
import ViewBox from 'assets/images/airdrop/box.png';
import ViewBox2 from 'assets/images/airdrop/box-2.png';
import ViewBoxs from 'assets/images/airdrop/box-s.png';
import ViewBoxBg from 'assets/images/airdrop/box-bg.png';
// import NoteX from 'assets/images/airdrop/x.png';
import NoteQ from 'assets/images/airdrop/q.png';
import PresaleBtnBg1 from 'assets/images/presale/btn-bg-1.webp';
import PresaleBtnBg2 from 'assets/images/presale/btn-bg-2.webp';
import { ReactComponent as BigSaleSvg } from 'assets/svg/bigsale.svg';
import { PageAirdropPcP } from './pcp';
import { PageAirdropHeroes } from './heroes';
import { useReadContract, useReadContracts } from 'wagmi';
import { useRecoilValue } from 'recoil';
import { stateAppChainInfo } from 'states/wallet';
import { fmtInt } from 'utils';
import { AppButton } from 'components/app/AppButton';
import { GlobalVar } from 'constants/constants';
import { useWallet } from 'hook/useWallet';
import { ABI_CONST_MOUNTED } from 'types/ABI_CONST';
import Counter from './Counter';
import { parseEther } from 'viem';

export const PagePreSale: React.FC<{}> = (props) => {
  const chainInfo = useRecoilValue(stateAppChainInfo);
  const wallet = useWallet();

  const currentPeriod = useReadContract({
    abi: ABI_CONST_MOUNTED.CRBox,
    address: chainInfo.CRBox.address,
    functionName: 'currentPeriod',
    query: {
      refetchInterval: 1000,
      select(data) {
        return data ?? 0;
      },
    },
  });

  const staticInfos = useReadContract({
    abi: ABI_CONST_MOUNTED.CRBox,
    address: chainInfo.CRBox.address,
    functionName: 'configs',
    args: [currentPeriod?.data ?? 0],
    query: {
      refetchInterval: 10000,
      select(data) {
        return {
          totalInventory: data?.[0] ?? 0n,
          maximunPreAddress: data?.[1] ?? 0n,
          blindBoxPrice: data?.[2] ?? 0n,
        };
      },
    },
  });

  const presaleInfos = useReadContracts({
    contracts: [
      { abi: ABI_CONST_MOUNTED.CRBox, address: chainInfo.CRBox.address, functionName: 'numberOfTimes', args: [currentPeriod?.data ?? 0, wallet.address] },
      { abi: ABI_CONST_MOUNTED.CRBox, address: chainInfo.CRBox.address, functionName: 'newSolds', args: [currentPeriod?.data ?? 0] },
    ],
    allowFailure: true,
    query: {
      refetchInterval: 1000,
      select(data) {
        return {
          numberOfTimes: data[0].result ?? 0n,
          sold: data[1].result ?? 0n,
        };
      },
    },
  });

  const isSoldOut = useMemo(() => {
    return presaleInfos.data?.sold === staticInfos.data?.totalInventory;
  }, [presaleInfos.data?.sold, staticInfos.data?.totalInventory]);

  return (
    <LayoutDefault>
      <PageIndexStyle>
        <div className="view">
          <div className="bg">
            {/* <img src={ViewBg1} /> */}
            <div />
          </div>
          <div className="title">Pre-sale</div>
          <div className="des">Get crypto rumble hero mystery boxes</div>
          <div className="content">
            <img className="box" width={327} src={ViewBox} />
            <PageAirdropHeroes />
            <div className="progress">
              <Counter soldout={isSoldOut} targetTime={new Date('2025-02-18T15:00:00+08:00')} />
              {fmtInt(presaleInfos.data?.sold)}
              <span>/ {fmtInt(staticInfos.data?.totalInventory)}</span>
            </div>
            <PageAirdropPcP className="sub-desc" fontFamily="Jockey One" fontSize={24}>Hero Mystery Boxes</PageAirdropPcP>
            <AppButton
              style={{ marginTop: 20, width: 368, height: 89, background: 'none', boxShadow: 'none', zIndex: 2 }}
              disabled={isSoldOut}
              className="app-button"
              onClick={wallet.requestTx(
                async () => {
                  // const claimAmount = false;
                  // if (!claimAmount) {
                  //   const handler = GlobalVar.modal.confirm({
                  //     wrapClassName: 'app-confirm-wrap',
                  //     centered: true,
                  //     maskClosable: true,
                  //     title: 'NOTE',
                  //     icon: null,
                  //     width: 600,
                  //     content: (
                  //       <ConfirmContent
                  //         img={
                  //           <div style={{ display: 'flex', alignItems: 'center' }}>
                  //             <img src={ViewBox2} width={128} />
                  //             <img src={NoteQ} width={32} style={{ marginTop: -40, marginLeft: -40 }} />
                  //           </div>
                  //         }
                  //         title="Not yet on sale"
                  //         des={'The sale will start in 0 hours, 0 minutes, and 0 seconds.'}
                  //       />
                  //     ),
                  //     footer: (
                  //       <AppButton onClick={() => handler.destroy()} className="app-confirm-btn" style={{ width: 130, height: 46 }}>
                  //         Okay
                  //       </AppButton>
                  //     ),
                  //   });
                  //   return;
                  // }
                  if (!wallet.acc.chain) return;
                  const overTheLimit = presaleInfos.data?.numberOfTimes === (staticInfos.data?.maximunPreAddress ?? 10n);
                  if (overTheLimit || isSoldOut) {
                    const handler = GlobalVar.modal.confirm({
                      wrapClassName: 'app-confirm-wrap',
                      centered: true,
                      maskClosable: true,
                      title: 'NOTE',
                      icon: null,
                      width: 600,
                      content: (
                        <ConfirmContent
                          img={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={ViewBox2} width={128} />
                              <img src={NoteQ} width={32} style={{ marginTop: -40, marginLeft: -40 }} />
                            </div>
                          }
                          title={overTheLimit ? 'Exceeds the limit.' : 'Sold out.'}
                          des={overTheLimit ? 'Sorry, you have reached the purchase limit.' : 'Sorry, all the mystery boxes are sold out.'}
                        />
                      ),
                      footer: (
                        <AppButton onClick={() => handler.destroy()} className="app-confirm-btn" style={{ width: 130, height: 46 }}>
                          Okay
                        </AppButton>
                      ),
                    });
                    return;
                  }
                  if (staticInfos.data?.blindBoxPrice) {
                    return wallet.write.writeContractAsync({
                      abi: ABI_CONST_MOUNTED.CRBox,
                      address: chainInfo.CRBox.address,
                      functionName: 'sell',
                      value: staticInfos.data.blindBoxPrice,
                    });
                  }
                },
                'Purchase hero mystery boxes',
                () => {
                  // infos.refetch();
                  const handler = GlobalVar.modal.confirm({
                    wrapClassName: 'app-confirm-wrap',
                    centered: true,
                    maskClosable: true,
                    title: 'CONGRATULATIONS',
                    icon: null,
                    width: 600,
                    content: (
                      <ConfirmContent
                        img={
                          <div className="success-imgs">
                            <img className="box" src={ViewBox2} width={128} />
                            <img className="box-s" src={ViewBoxs} width={234} />
                            <img className="box-bg" src={ViewBoxBg} width={272} />
                          </div>
                        }
                        title="You've successfully purchased your Crypto Rumble Hero Mystery Box!"
                        des="You're so lucky! Enjoy your mystery box."
                      />
                    ),
                    footer: (
                      <AppButton onClick={() => handler.destroy()} className="app-confirm-btn" style={{ width: 130, height: 46 }}>
                        Okay
                      </AppButton>
                    ),
                  });
                },
              )}
            >
              <BigSaleSvg className="bg" />
              <div className="price">
                BUY 0.0013 ETH / Box
              </div>
            </AppButton>
            <div className="presale-bg-1" />
            <div className="presale-bg-2" />
          </div>
        </div>
        <div className="view">
          <div className="bg">
            <img src={ViewBg2} />
            <div className="bg2-div" />
          </div>
          <div className="title">Rules</div>
          <div className="pcp">
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              1. The Pre-Order Limit For Blind Boxes Is 3000.
            </PageAirdropPcP>
            <div className="hr" />
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              2. The Purchase Limit For Blind Boxes Is 10 Per Address.
            </PageAirdropPcP>
            <div className="hr" />
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              3. The Event Will End Once The Pre-Order Limit Is Reached.
            </PageAirdropPcP>
            <div className="hr" />
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              4. The Boxes Can Only Be Opened After The Public Test.
            </PageAirdropPcP>
          </div>
        </div>
      </PageIndexStyle>
    </LayoutDefault>
  );
};

const DataItem: React.FC<{ img: React.ReactNode; name: string; value: string }> = (props) => {
  return (
    <DataItemStyle>
      {props.img}
      <div className="name">{props.name}</div>
      <div className="value">{props.value}</div>
    </DataItemStyle>
  );
};

const ConfirmContent: React.FC<{ img: React.ReactNode; title: string; des: string }> = (props) => {
  return (
    <ConfirmContentStyle>
      {props.img}
      <div className="title">{props.title}</div>
      <div className="des">{props.des}</div>
    </ConfirmContentStyle>
  );
};

const ConfirmContentStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > .title {
    font-size: 16px;
    font-weight: 700;
  }
  > .des {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
  }
  > .success-imgs {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    > .box {
      position: relative;
      z-index: 4;
    }
    > .box-s {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    > .box-bg {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`;

const DataItemStyle = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 44px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 12px 0 8px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: 400;
  > img {
    user-select: none;
  }
  > .value {
    color: rgba(246, 185, 69, 1);
  }
  > .name {
    color: #fff;
  }
`;

const PageIndexStyle = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  > .view {
    position: relative;
    z-index: 1;
    min-height: 700px;
    color: #fff;
    padding-top: 128px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    + .view {
      padding-top: 0;
    }
    > .bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url(${ViewBg1}) no-repeat top center / cover; 
      z-index: -1;
      pointer-events: none;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(18, 18, 18, 0.5) 0%, #121212 100%);
        &.bg2-div {
          background: linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 0.7) 50%, rgba(0, 0, 0, 1) 100%);
        }
      }
    }

    > .title {
      font-size: 32px;
      font-weight: 700;
    }
    > .des {
      font-size: 16px;
      font-weight: 400;
      opacity: 0.7;
    }
    > .content {
      width: 100vw;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .datas {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        z-index: 2;
        position: relative;
        > .hr {
          background-color: rgba(255, 255, 255, 0.1);
          width: 1px;
          height: 20px;
        }
      }
      > .box {
        margin-left: 40px;
        animation: box-will-open 2.5s ease-in-out infinite;
        pointer-events: none;
        position: relative;
        z-index: 10;
        user-select: none;
      }
      @keyframes box-will-open {
        10% {
          transform: rotate(15deg) scale(0.8);
        }
        20% {
          transform: rotate(-10deg);
        }
        30% {
          transform: rotate(5deg) scale(0.9);
        }
        40% {
          transform: rotate(-5deg) scale(1.1);
        }
        50%,
        100% {
          transform: rotate(0deg) scale(1);
        }
      }
      > .progress {
        font-family: "Jockey One";
        font-size: 64px;
        font-weight: 400;
        background-image: linear-gradient(180deg, #f8df74 23.44%, #f2d03e 61.46%);
        background-clip: text;
        color: transparent;
        z-index: 2;
        position: relative;
        > span {
          font-size: 64px;
          font-weight: 400;
          background-image: linear-gradient(180deg, #909af9 13.54%, #515fe4 69.27%);
          background-clip: text;
          color: transparent;
          margin-left: 20px;
        }
        > .sold-out {
          position: absolute;
          z-index: 3;
          color: #E60012;
          white-space: nowrap;
          top: 0;
          left: 50%;
          transform: translate(-50%, -80%);
          font-size: 42px;
        }
      }
      > .app-button {
        > .bg {
          position: absolute;
          z-index: 2; 
        }
        > .anticon {
          display: none;
        }
        > .price {
          z-index: 3;
          font-size: 20px;
          font-weight: 800;
          filter: drop-shadow(0px 1px 0px #FFFFFF80);
          padding: 6px 0 0;
        }
      }
      > .sub-desc {
        z-index: 2;
      }
      > .presale-bg-1,
      > .presale-bg-2 {
        position: absolute;
      }
      > .presale-bg-1 {
        width: 400px;
        height: 180px;
        background: url(${PresaleBtnBg1}) no-repeat center / contain;
        bottom: 40px;
      }
      > .presale-bg-2 {
        width: 480px;
        height: 140px;
        background: url(${PresaleBtnBg2}) no-repeat center / contain;
        bottom: 40px;
      }
    }
    > .pcp {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      > .hr {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
`;
