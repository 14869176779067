import { mainnet } from "constants/chains";

export default {
  [`${mainnet.id}`]: {
    Hero: {
      "address": "0x996221142c445810310C260Bd2eF38F0aB1d6a24"
    },
    HeroEdition: {
      "address": "0x97c49B9E02Fc83Eaa2d44901a21ABd32394792F6"
    },
    CRBox: {
      "address": "0x60CFdb800E04dc106F4c35FC0714120001aD371B"
    },
    // Restaking: "0xa415b3De245d8B616e92A0587954dAF8e8Edb0f5",
    // WETH: "",
    // wstETH: "",
    // ezETH: "",
    // STONE: "",
    // weETH: "",
  },
} as const;
