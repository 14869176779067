import React, { useEffect, useState } from 'react'

interface ICounter {
  targetTime: Date;
  soldout?: boolean;
}

const Counter: React.FC<ICounter> = ({ targetTime, soldout }) => {
  const calculateTimeLeft = () => {
    const difference = targetTime.getTime() - Date.now();
    let [hours, minutes, seconds] = [0, 0, 0];
    let started = true;
    if (difference > 0) {
      hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      minutes = Math.floor((difference / (1000 * 60)) % 60);
      seconds = Math.floor((difference / 1000) % 60);
      started = false;
    }
    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
      started,
    };
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTime]);

  return (
    <div className="sold-out">
      {!timeLeft.started && `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}
      {timeLeft.started && soldout ? 'Sold out!' : null}
    </div>
  )
};

export default Counter;
